import { pdfContentUrl } from '../../api'

export const DERMATITIS_DOWNLOADS = {
  name: 'Atopic Dermatitis Totum',
  title: 'ATOPIC DERMATITIS',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'EFFICACY & SAFETY DATA',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/atopic-dermatitis-efficacy-and-safety-data.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'SKIN OF COLOR <br />RESOURCE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/skin-of-color-resource.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'PATIENT BROCHURE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/Atopic-dermatitis-patient-brochure.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT DOSING',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/dupixent-dosing.pdf`,
        },
      },
    },
  ],
}
