import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { IAlertContentButton } from '../ContentContainer'
import Button from '../Widgets/Button'
import ButtonReverse from '../Widgets/ButtonReverse'
import { Wrapper, Modal, ModalTop, ModalBody, ModalBottom, ModelBodyWrapper } from './styles'

interface Props {
  message: string
  show: boolean
  closeText: string
  closeCallback?: Function
  buttons?: IAlertContentButton[]
}

export const Alert: React.FC<Props> = ({ message, show, closeText, closeCallback, buttons }) => {
  const [visible, setVisible] = useState(show)

  return (
    <>
      {visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: 1000 }}
        >
          <Wrapper>
            <Modal>
              <ModalTop />
              <ModelBodyWrapper>
                <ModalBody>
                  <p>{message}</p>
                </ModalBody>
                <ModalBottom>
                  <ButtonReverse
                    text={closeText}
                    handleSubmit={() => {
                      setVisible(false)
                      if (closeCallback) closeCallback()
                    }}
                  />
                  {buttons &&
                    buttons.map(({ text, callback }, idx) => <Button key={idx} text={text} handleSubmit={callback} />)}
                </ModalBottom>
              </ModelBodyWrapper>
            </Modal>
          </Wrapper>
        </motion.div>
      )}
    </>
  )
}

export default Alert
