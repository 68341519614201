import styled from "styled-components";
import menuBg from '../../assets/images/menu-bg.png'
import logoSymbol from '../../assets/images/logo-symbol-white-small.png'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  background: #CCC;
  color: #009b77;
  width: 100%;
  max-width: 380px;
  text-align: center;
  border-radius: 6px 6px 0 0;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.8);
  background-image: linear-gradient(to right, #C2C1C1, 20%, #fff);
`;

export const ModalTop = styled.div`
  background: #009b77;
  height: 25px;
  border-radius: 6px 6px 0 0;
  background-image: url(${menuBg}); 
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ModelBodyWrapper = styled.div`
  border: 2px solid #009b77;
  border-top: none;
`;

export const ModalBody = styled.div`
  background-image: url(${logoSymbol});
  /* background-position: -65% 35%; */
  background-size: 15%;
  background-repeat: no-repeat;
  padding: 20px;

  p {
    font-weight: bold;
  }
`;

export const ModalBottom = styled.div`
  padding-bottom: 20px;
`;
