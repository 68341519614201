import TagManager from 'react-gtm-module'

export const gaLogEvent = (event: any) => {
  window.parent.postMessage (JSON.stringify(event), process.env.REACT_APP_HOSTED_IFRAME_GA)
  console.log("GA:", JSON.stringify(event));
}

export const gaInitalise = ({ gtmId }: { gtmId: string }) => {
  const tagManagerArgs = {
    gtmId,
  }
  TagManager.initialize(tagManagerArgs)
}
