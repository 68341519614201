import styled, { keyframes } from 'styled-components'

const fadeOut = keyframes`
  0% { opacity: 1;}
  99% { opacity: 0.01;width: 100%; height: 100%;}
  100% { opacity: 0;width: 0; height: 0;}
`

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;

  &.visible {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  &.hidden {
    opacity: 0;
  }

  &.click-through {
    animation: ${fadeOut} 1s;
    animation-fill-mode: forwards;
    pointer-events: none;
  }
`
