export const CONTACTAREP_CONTENT = {
  content: {
    message:
      'To connect with a DUPIXENT Field Representative regarding product-related questions, please click the connect button which will open a new browser window',
    buttons: [
      {
        text: 'CONNECT',
        link: 'https://www.dupixenthcp.com/atopicdermatitis/access-support/request-a-field-representative',
      },
    ],
  },
}
