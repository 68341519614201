import isMobile from './mobileDetector'
const { detect } = require('detect-browser')
const browser = detect()

const showWarning = () => {
    if (isMobile) return false
    switch (browser && browser.name) {
        case 'chrome':
        case 'safari':
        case 'ios':
        return false
        default:
        return true
    }
}

export default showWarning;