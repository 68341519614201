export const hasSeenFlyThrough = () => {
  const flyThroughSeen = localStorage.getItem('FlyThroughSeen')
  if (flyThroughSeen === 'true') {
    return true
  } else {
    return true // true REMOVES FLYTHROUGH VIDEO FROM SCENE ALL TOGETHER - SET TO false IF WANT TO ADD BACK IN USING LOCALSTORAGE
  }
}

export const setHasSeenFlyThrough = () => {
  try {
    localStorage.setItem('FlyThroughSeen', 'true')
  } catch (error) {}
}

export const setHasIntro = () => {
  try {
    localStorage.setItem('IntroSeen', 'true')
  } catch (error) {}
}