import { IGaContentProps, IGaContentViewedEvent, gaLogEvent } from '..'

export const gaContentViewed = ({ type, name }: IGaContentProps) => {
  const event: IGaContentViewedEvent = {
    event: 'contentViewed',
    DLV_content_viewed_type: type,
    DLV_content_viewed_name: name,
  }
  gaLogEvent(event)
}
