import { pdfContentUrl } from '../../api'

export const ASTHMA_DOWNLOADS = {
  name: 'Asthma Totum',
  title: 'EOSINOPHILIC OR<br />OCS-DEPENDENT ASTHMA',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'ALLERGIC ASTHMA PATIENT WITH ELEVATED EOS RESOURCE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/AllergicAsthmawith ElevatedEOSPatientProfileLeaveBehind.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'OCS-DEPENDENT ASTHMA PATIENT RESOURCE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/OCS-DependentAsthma PatientProfileLeave Behind_.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'SEVERE ASTHMA PATIENT WITH ELEVATED EOS RESOURCE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/severe-asthma-patient-with-elevated-eos.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'CHILD WITH UNCONTROLLED EOSINOPHILIC ASTHMA RESOURCE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/child-with-uncontrolled-eosinophilic-asthma-resource.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'TRAVERSE STUDY RESOURCE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/traverse-study-resource.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'GUIDE FOR YOUR PATIENTS PRESCRIBED DUPIXENT',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/guide-for-your-patients-prescribed-dupixient.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'ASTHMA PATIENT BROCHURE',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/asthma-patient-brochure.pdf`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT DOSING',
      content: {
        contentTemplate: 'PDF',
        content: {
          src: `${pdfContentUrl}/dupixent-dosing.pdf`,
        },
      },
    },
  ],
}
