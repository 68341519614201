import React, { useState, useContext } from 'react'
import { MenuItem } from '../../@types/types'
import { EventContext, setEvent } from '../../reducers/event'
import { UiContext, setAction, setAlert } from '../../reducers/ui'

import { MobileHeader } from './MobileHeader'
import CycleButtons from '../CycleButtons'
import { Wrapper, MenuWrapper, MobileMenuItem, MobileMenuItemAnchor } from './styles'

interface Props {
  menuItems?: MenuItem[]
  onClose: Function
  open?: boolean
  popUpShowing: boolean
  cycleHidden: boolean
  setCycleHidden: Function
  setCurrentBooth: any
  currentBooth: any
}

/* eslint react-hooks/exhaustive-deps: "off" */
const MobileNavigation: React.FC<Props> = ({ setCycleHidden, cycleHidden, menuItems, onClose, open, popUpShowing, currentBooth, setCurrentBooth }) => {
  const [showNav, setShowNav] = useState<boolean>(false);
  const [showSubHeaders, setShowSubHeaders] = useState(false);

  const { dispatch: eventDispatch, state: eventState } = useContext(EventContext)
  const { dispatch: uiDispatch, state: uiState } = useContext(UiContext)


  const onToggle = () => {
    if (uiState.currentAction !== '') {
      uiDispatch(setAction(''))
      onClose()
      return
    }
    setShowNav(!showNav)
  }

  const updateMenu = ({ instance, alert }) => {
	if (alert) {
      uiDispatch(setAlert(alert))
      return
    }
    if (instance) {
      eventDispatch(setEvent('MOVE_TO_OBJECT', instance))

      setCycleHidden(false)
      setShowNav(false)
    }
  }

  return (
	  <>
		<CycleButtons uiState={uiState} eventState={eventState} cycleHandler={updateMenu} currentBooth={currentBooth} setCurrentBooth={setCurrentBooth} />
    <Wrapper open={open}>
      <>
        <MobileHeader popUpShowing={popUpShowing} toggle={onToggle} showClose={showNav} menuVisible={uiState.open || !eventState.flyThroughViewed} />
		{showNav && (
          <MenuWrapper>
            {menuItems.map(({ title, instance, alert, dropdown, link, mobileOnly, desktopOnly }, idx) => (
              <>
                {!link && !desktopOnly ? (
                  <>
                    <MobileMenuItem
                      dangerouslySetInnerHTML={{ __html: title }}
                      key={idx}
                      onClick={() => {
                          if (instance) {
                              setCurrentBooth(instance)
                          }
                          if (dropdown) {
                            setShowSubHeaders(!showSubHeaders)
                          }
                        updateMenu({ instance, alert });
                      }}
                      className={`ripple ${dropdown && 'dropdown-closed'} ${dropdown && !showSubHeaders && 'dropdown-open'}`}
                    />
                  </>
                ) : (
                  mobileOnly && <a href={link} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer"><MobileMenuItem>{title}</MobileMenuItem></a>
                )}
                
                {showSubHeaders && dropdown && dropdown.map(({ subTitle, link }) => (
                  <MobileMenuItemAnchor href={link} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: subTitle }}></MobileMenuItemAnchor> 
                ))}
              </>
            ))}
          </MenuWrapper>
        )}
      </>
    </Wrapper>
	  </>
  )
}

export { MobileNavigation }
