import styled from 'styled-components'
import requestARepIcon from "../../assets/icons/request-a-rep-icon.png";
import arrowDownGreen from "../../assets/icons/arrow-down-green.png";
import arrowUpGreen from "../../assets/icons/arrow-up-green.png";
import arrowDownGrey from '../../assets/icons/arrow-down-grey.png'
import arrowDownWhite from '../../assets/icons/arrow-down-white.png'
import menuBg from '../../assets/images/menu-bg.png'
import menuChildBg from '../../assets/images/arrow-double.png';
import arrowUpWhite from "../../assets/icons/arrow-up-white.png"
import arrowUpGrey from "../../assets/icons/arrow-up-grey.png"

export const NavToggle = styled.div`
  z-index: 10;
  font-weight: bold;
  height: 35px;
  width: 300px;
  background-image: linear-gradient(105deg, #75c34e, #009b77, #187c2e);

  .top-arrow_close {
    background-image: url(${arrowDownWhite}), linear-gradient(105deg, #75c34e, #009b77, #187c2e);
    background-repeat: no-repeat;
    background-position: 50%;
    width: 300px;
    height: 37px;

    :hover {
      background: white;
      background-image: url(${arrowDownGrey});
      background-repeat: no-repeat;
      background-position: 50%;
      cursor: pointer;
    }
  }
`;

export const NavMenu = styled.div`
  background-image: url(${menuBg}); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #FFF;
  padding: 4px;
`;

export const NavToggleStatic = styled.div<{ open: boolean }>`
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100px;
  left: calc(50% - 50px);
  border-radius: 8px;
  font-weight: bold;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  visibility: ${(props) => (props.open ? "hidden" : "visible")};
  transition: visibility 0s ease-in 1.5s;

  .top {
    background-image: linear-gradient(105deg, #75c34e, #009b77, #187c2e);
    border-radius: 8px 8px 0 0;
    color: white;
    height: 25px;
    line-height: 25px;
    text-align: center;

    .top-arrow {
      background-image: url(${arrowUpWhite}), linear-gradient(105deg, #75c34e, #009b77, #187c2e);
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: 8px 8px 0 0;
      height: 20px;
      z-index: 1000;
    }
  }

  .bottom {
    background: white;
    border-radius: 0 0 8px 8px;
    color: #11b07e;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  :hover { 
    cursor: pointer;
    background: white;
  }

  :hover .top-arrow {
    background-image: url(${arrowUpGrey});
  }

  :hover .top {
    background: white;
    color: #009b77;
  }

  :hover .bottom {
    background-image: linear-gradient(105deg, #75c34e, #009b77, #187c2e);
    color: white;
  }
`;

export const NavigationWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  max-height: 575px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  z-index: 10;
  color: #11b07e;

  @media only screen and (min-width: 768px) {
    left: calc(50% - 150px);
    width: 300px;
  }
`;

export const InnerWrapper = styled.div`
  height: calc(100% - 60px);
`;

export const UL = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
`;

export const LI1 = styled.li`
  flex: 1;
  overflow: hidden;
  background-image: linear-gradient(105deg, #bbb, 20%, #fff);
  border-bottom: 1px solid #ccc;
  position: relative;
  z-index: 1;
  cursor: pointer;

  ::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(105deg, #75c34e, #009b77, #187c2e);
    z-index: -1;
    transition: opacity 0.5s linear;
    opacity: 0;
  }

  :hover::before {
    opacity: 1;
  }

  :hover {
    color: white;
  }

  &:last-child {
    background-image: url(${requestARepIcon});
    background-color: #FF9900;
    background-position: 24% center;
    background-repeat: no-repeat;
    border-left: none;
    border-bottom: 0;
    color: white;
    flex: 1;
    overflow: hidden;

    ::before {
      background-image: url(${requestARepIcon}), linear-gradient(105deg, #75c34e, #009b77, #187c2e);
      background-position: 24% center;
      background-repeat: no-repeat;
    }

    :hover {
      background-image: url(${requestARepIcon}), linear-gradient(105deg, #75c34e, #009b77, #187c2e);
      cursor: pointer;
      color: white;
    }
  }
`;

export const LI2 = styled.li`
`;

export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${menuChildBg});
  background-size: 20% 100%;
  background-repeat: no-repeat;
  border-bottom: 1px solid grey;
`;

export const Content = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ContentIcon = styled.div`
  width: 100%;
  margin-left: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    max-height: 20px;
    margin-right: 5px;
  }

  p {
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    line-height: 22px;
  }
`;

// MOBILE

export const Wrapper = styled.div<{ open: boolean }>`
  width: 100%;
  z-index: 999;
  position: absolute;
  visibility: ${(props) => (props.open ? "hidden" : "visible")};
  transition: visibility 0s ease-in 1.5s;
`;

export const ContentWrapper = styled.div<{ showHeader: boolean }>`
  background: #fff;
  width: 100%;
  height: ${(props) => (!props.showHeader ? "100%" : "calc(100% - 90px)")};
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
`;

export const ContentChildren = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
`;

export const MenuWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh - 90px);
  z-index: 999;
  animation: 0.9s menuDropdown ease-in-out;
  @keyframes menuDropdown {
    0% { height: 0px }
    100% { height: calc(100vh - 90px) }
  }
`;

export const MobileMenuItem = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  align-items: center;
  background: #f5f5f5;
  border-bottom: 1px solid #ededed;
  border-left: 5px solid #2ca681;
  color: #2ca681;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  min-height: 60px;
  padding: 0 36px;
  text-align: center;
  &:hover, &:active, &:focus {
    background: #11b07e;
    color: white;
    -webkit-tap-highlight-color: #11b07e;
  }
  &.dropdown-closed {
    background-image: url(${arrowDownGreen});
    background-repeat: no-repeat;
    background-position: 90%;
    &:hover, &:active, &:focus {
      background-color: #f5f5f5;
      color: #2ca681;
    }
  }
  &.dropdown-open {
    background-image: url(${arrowUpGreen});
    background-repeat: no-repeat;
    background-position: 90%;
    &:hover, &:active, &:focus {
      background-color: #f5f5f5;
      color: #2ca681;
    }
  }
`;

export const MobileMenuItemAnchor = styled.a`
  display: flex;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  align-items: center;
  background: white;
  color: #796E65;
  display: flex;
  font-size: 15px;
  min-height: 45px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
`;

export const HeadingContentArrow = styled.p<{ items: any, active: any }>`
  align-items: center;
  transform: ${props => props.active ? 'rotate(180deg)' : 'rotate(0deg)'};
  background-image: ${props => props.items ? `url(${arrowDownGreen})` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  height: 60px;
  padding: 0 16px 0 0;
`

export const MenuHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  padding: 10px 20px;
  background: white;
`;

export const HeadingContent = styled.div`
  background: rgb(245, 245, 245);
  border-bottom: 1px solid #CCC;
  color: #796E65;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
`

export const HeadingContentName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 60px;
  padding: 0 36px;

  &:hover {
    border-left: 5px solid orange;
    cursor: pointer;
  }
`

export const SubHeadingContentArrow = styled.p<{ items: any, active: any }>`
  align-items: center;
  background-image: ${props => props.items ? `url(${arrowDownGrey})` : 'none'};
  transform: ${props => props.active ? 'rotate(0deg)' : 'rotate(180deg)'};
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  height: 60px;
  padding: 0 16px 0 0;
`

export const SubHeadingContent = styled.div`
  background: #FFF;
`

export const SubHeadingContentName = styled.div`
  align-items: center;
  border-top: 1px solid #CCC;
  color: #5B5B5B;
  display: flex;
  height: 60px;
  padding: 0 36px;

  &:hover {
    border-left: 5px solid orange;
  }

`