import { IGaVideoPercentageProps, IGaVideoPercentageViewedEvent, gaLogEvent } from '../'

const logEvent = ({ name, percentage }: { name: string; percentage: number }) => {
  const event: IGaVideoPercentageViewedEvent = {
    event: 'percentageVideoViewed',
    DLV_content_video_name: name,
    DLV_content_video_percentage: percentage,
  }
  return gaLogEvent(event)
}

export const gaPercentageVideoViewed = (data: IGaVideoPercentageProps) => {
  const { hitRate, video, name } = data

  const percentageHitRate = hitRate ? hitRate : 4

  let intervalInstance: number

  const interval = function (cb: Function) {
    intervalInstance = setInterval(cb, 2000)
  }

  video.addEventListener('play', function (event) {
    const timePoint = video.duration / percentageHitRate
    const conditions = Array.apply(null, Array(percentageHitRate)).map(function (condition, i) {
      const min = timePoint * (i + 1)
      const max = timePoint * (i + 2)

      return {
        min: min,
        max: max,
        response: (100 / percentageHitRate) * (i + 1),
        valid: min > video.currentTime,
      }
    })

    const generatePercentageViewed = () => {
      conditions.forEach(function (condition) {
        if (video.currentTime >= condition.min && video.currentTime < condition.max && condition.valid) {
          condition.valid = false
          logEvent({ name, percentage: condition.response })
        }
      })
    }
    interval(generatePercentageViewed)
  })

  video.addEventListener('ended', function (event) {
    clearInterval(intervalInstance)
    logEvent({ name, percentage: 100 })
  })
  video.addEventListener('pause', function (event) {
    clearInterval(intervalInstance)
  })
}
