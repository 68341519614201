import React, { ReactElement, useState, useEffect } from 'react'

import * as CONTENT from '../../config/content'
import arrowCycleRight from '../../assets/icons/cycle-right-arrow.png'
import arrowCycleLeft from '../../assets/icons/cycle-left-arrow.png'

interface Props {
    eventState: any;
	uiState: any;
    cycleHandler: Function;
    currentBooth: string;
    setCurrentBooth: Function;
}

// Removes any booths without a model
const formatConfig = () => {
    const booths = CONTENT.contentNavigation;

    const updatedBooths = booths.filter(booth => booth.instance !== undefined)

    return updatedBooths;
}

function CycleButtons({ uiState, eventState, currentBooth, cycleHandler, setCurrentBooth }: Props): ReactElement {
    const [arrowsHidden, setArrowsHidden] = useState(true)
    const [cycleLeftMax, setCycleLeftMax] = useState(false)
    const [cycleRightMax, setCycleRightMax] = useState(false)

    const booths = formatConfig();

    useEffect(() => {
        if (uiState.open || eventState.cameraMoving || currentBooth === '') {
            setArrowsHidden(true);
        } else if (!uiState.open && !eventState.cameraMoving) {
            setArrowsHidden(false);
        }
    }, [eventState.cameraMoving]) // eslint-disable-line

    useEffect(() => {
        if (eventState.eventAction !== '') {
            setCurrentBooth(eventState.eventAction)
            const getCurrentBooth = (el) => el.instance === eventState.eventAction;
            const nextBooth = booths[booths.findIndex(getCurrentBooth) + 1];
            const prevBooth = booths[booths.findIndex(getCurrentBooth) - 1];

            switch (prevBooth) {
                case undefined:
                    setCycleLeftMax(true)
                    break;

                default:
                    setCycleLeftMax(false)
                    break;
            }

            switch (nextBooth) {
                case undefined:
                    setCycleRightMax(true)
                    break;

                default:
                    setCycleRightMax(false)
                    break;
            }
        } else if (eventState.eventName === 'MOVE_TO_START') {
            setCurrentBooth('')
        }

    }, [eventState, setCurrentBooth, currentBooth, booths])

    const cycleNext = (currBooth: string, setNewBooth: Function) => {
        const getCurrentBooth = (el) => el.instance === currBooth;
        const nextBooth = booths[booths.findIndex(getCurrentBooth) + 1];
        const lastIndex = booths.length - 1;

        if (nextBooth && nextBooth.instance !== booths[lastIndex].instance) {
            setNewBooth(nextBooth.instance);
            cycleHandler({instance: nextBooth.instance, alert: undefined});
            setCycleLeftMax(false);
            setCycleRightMax(false);
        } else {
            cycleHandler({instance: nextBooth.instance, alert: undefined});
            setCycleRightMax(true);
        }
    }

    const cyclePrev = (currBooth: string, setNewBooth: Function) => {
        const getCurrentBooth = (el) => el.instance === currBooth;
        const prevBooth = booths[booths.findIndex(getCurrentBooth) - 1];

        if (prevBooth && prevBooth.instance !== booths[0].instance) {
            setNewBooth(prevBooth.instance);
            cycleHandler({instance: prevBooth.instance, alert: undefined});
            setCycleLeftMax(false);
            setCycleRightMax(false);
        } else {
            cycleHandler({instance: prevBooth.instance, alert: undefined});
            setCycleLeftMax(true);
            setCycleRightMax(false);
        }
    }

	return (
		<>
            <span style={{ border: 'none', background: 'none', position: 'absolute', top: '45%', left: '0', display: arrowsHidden || cycleLeftMax ? 'none' : 'inline', width: '44px', height: '80' }} onClick={() => cyclePrev(currentBooth, setCurrentBooth)}>
				<img width="100%" height="100%" src={arrowCycleLeft} alt="arrow-cycle-left" />
			</span>
            <span style={{ border: 'none', background: 'none', position: 'absolute', top: '45%', right: '0', display: arrowsHidden || cycleRightMax ? 'none' : 'inline', width: '44px', height: '80' }} onClick={() => cycleNext(currentBooth, setCurrentBooth)}>
				<img width="100%" height="100%" src={arrowCycleRight} alt="arrow-cycle-right" />
			</span>
		</>
	)
}

export default CycleButtons;
