import { Color3, Vector3, Tools } from '@babylonjs/core'
import { GuiLabelMesh } from '../@types/3DTypes'

export interface SceneConfig {
  debug?: boolean
  skyboxSize?: number
  skyboxColor?: Color3
  initialCameraPos: Vector3
  initialCameraTarget?: Vector3
  initialCameraRotation?: Vector3
  maxCameraRotationX?: number
  minCameraRotationX?: number
  maxCameraRotationY?: number
  minCameraRotationY?: number
  cameraSpeed?: number
  cameraMinZ?: number
  hemisphericLightDirection?: Vector3
  hemisphericLightIntensity?: number
  glowIntensity?: number
  skyboxTexture?: string
  skyboxTextureLevel?: number
  groundTextureLevel?: number
  videoBrightness?: number
  mobileMaxCameraRotationY?: any,
  mobileMinCameraRotationY?: any,
  touchAngularSensibility?: number
  defaultGuiLabel?: GuiLabelMesh
}

const labelBreakPoint = 375;

const sceneConfig: SceneConfig = {
  debug: false,
  cameraSpeed: 0.00000000001, // stops arrow key controls working
  hemisphericLightIntensity: 2,
  maxCameraRotationY: 3.7,
  minCameraRotationY: 2.7,
  minCameraRotationX: -0.1,
  maxCameraRotationX: 0.3,
  mobileMaxCameraRotationY: 1.95,
  mobileMinCameraRotationY: 1.22,
  initialCameraPos: new Vector3(0, 5, 12.5),
  initialCameraRotation: new Vector3(Tools.ToRadians(15), Tools.ToRadians(180), 0),
  glowIntensity: 1,
  skyboxTexture: 'https://uploads.dupixentvirtualbooth.com/uscongress/content/images/sky-tex.png',
  skyboxTextureLevel: 1.1,
  groundTextureLevel: 2.5,
  videoBrightness: 1.25,
  touchAngularSensibility: 5000,
  defaultGuiLabel: {
    rect: {
      width: window.innerWidth < labelBreakPoint ? '240px' : '290px',
      height: '100px',
      color: '#009B77',
      thickness: 4,
      background: '#009B77',
    },
    label: {
      fontSize: (window.innerWidth < labelBreakPoint) ? '13px' : '16px',
      color: '#fff',
      fontWeight: '100',
      text: '&#xF30A;',
    },
    arrow: {
      fontSize: '26px',
      color: '#fff',
      fontWeight: '600',
      fontFamily: 'bootstrap-icons',
    },
    ellipse: {
      width: '30px',
      height: '30px',
      color: 'transparent',
      background: 'transparent',
      zIndex: 99999,
      linkOffsetX: (window.innerWidth < labelBreakPoint) ? '0' : '-120',
    },
  },
}

export default sceneConfig
