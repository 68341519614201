import React from 'react'

import styled from 'styled-components'
import { motion } from 'framer-motion'
import mobileAlt from '../../assets/icons/mobile-alt.svg'

const OrientationWarning = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
  margin: auto;
  background: rgba(0, 0, 0, 0.9);
  display: none;
  @media screen and (min-aspect-ratio: 13/9) and (max-width: 767px) {
    display: block;
  }
`

const WarningWrap = styled.div`
  color: white;
  position: absolute;
  padding-left: 1rem;
  padding-right: 1rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: calc(100% - 2rem);
  height: 50vh;
`

const WarningText = styled.h2`
  font-weight: bold;
`

type WarningProps = {
  content?: string
}

const MobileLandscapeOverlay: React.FC<WarningProps> = ({ content }) => {
  return (
    <OrientationWarning>
      <WarningWrap>
        <div style={{ transform: 'rotate(90deg)' }}>
          <motion.img
            style={{ fontSize: '4rem' }}
            animate={{ rotate: -90 }}
            transition={{
              duration: 1,
              delay: 2.5,
              ease: 'easeOut',
              yoyo: Infinity,
              repeatDelay: 2.5,
            }}
            src={mobileAlt}
            alt="mobile-alt"
            width="100"
          />
        </div>
        <WarningText>{content.overlayMessage}</WarningText>
      </WarningWrap>
    </OrientationWarning>
  )
}

export default MobileLandscapeOverlay
