import React from 'react'
import styled from 'styled-components'

const Btn = styled.button`
  background-color: #11b07e;
  border: 1px solid white;
  color: white;
  opacity: 0.75;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: initial;
  line-height: normal;
  min-width: 80px;
  height: 40px;
  transition: background-color 0.25s ease-in-out;
  outline: none;
  &:hover {
    background-color: white;
    cursor: pointer;
    color: #11b07e;
    border: 1px solid #11b07e;
    outline: none;
  }
  &:active {
    outline: none;
  }
`

type ButtonProps = {
  handleSubmit?: any
  text?: string
  isDisabled?: boolean
  icon?: JSX.Element
}

const Button: React.FC<ButtonProps> = ({ text, handleSubmit, isDisabled, icon }) => (
  <Btn onClick={handleSubmit} disabled={isDisabled}>
    {text}
    {icon}
  </Btn>
)

export default Button
