import { videoContentUrl } from '../../api'

export const CRSwNP_VIDEOS = {
  name: 'CRSwNP Screen',
  title: 'CRSwNP',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'MECHANISM OF ACTION (MOA)',
      content: {
        contentTemplate: 'VIDEO',
        content: {
          src: `${videoContentUrl}/crswnp-moa-0431.mp4`,
          poster: `${videoContentUrl}/posters/crswnp-moa-0431-poster.png`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'LMK-CT',
      content: {
        contentTemplate: 'VIDEO',
        content: {
          src: `${videoContentUrl}/lmk-video-0388.mp4`,
          poster: `${videoContentUrl}/posters/lmk-video-0388-poster.png`,
        },
      },
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'EXPERT DISCUSSIONS',
      subItems: [
        {
          subMenuName: 'DR YEN: DUPIXENT FOR CRSwNP WITH PRIOR SURGERY PATIENT',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-yen-prior-surgery-patient-0516.mp4`,
            poster: `${videoContentUrl}/posters/dr-yen-prior-surgery-patient-0516-poster.png`,
          },
        },
        {
          subMenuName: 'DR RAMIREZ: DUPIXENT FOR CRSwNP AND HISTORY OF ASTHMA PATIENT',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-ramirez-history-of-asthma-0518.mp4`,
            poster: `${videoContentUrl}/posters/dr-ramirez-history-of-asthma-0518-poster.png`,
          },
        },
        {
          subMenuName: 'DR YEN & DR RAMIREZ: DUPIXENT FOR CRSwNP AND COMORBID NSAID-ERD PATIENT',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-yen-dr-ramirez-0519.mp4`,
            poster: `${videoContentUrl}/posters/dr-yen-dr-ramirez-0519-poster.png`,
          },
        },
        {
          subMenuName: 'DR SHER: CRSwNP ENDPOINTS AND DATA',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-endpoints-and-data-0253.mp4`,
            poster: `${videoContentUrl}/posters/dr-sher-endpoints-and-data-0253-poster.png`,
          },
        },
        {
          subMenuName: 'DR SHER: CRSwNP POOLED ANALYSIS',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-pooled-analysis-0254.mp4`,
            poster: `${videoContentUrl}/posters/dr-sher-pooled-analysis-0254-poster.png`,
          },
        },
        {
          subMenuName: 'DR SHER: DISCUSSION OF MOA',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-moa-discussion-0251.mp4`,
            poster: `${videoContentUrl}/posters/dr-sher-moa-discussion-0251-poster.png`,
          },
        },
        {
          subMenuName: 'DR SHER: WHAT IS TYPE 2 INFLAMMATION?',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-what-is-type-2-inflammation.mp4`,
            poster: `${videoContentUrl}/posters/CRSwNP-DrSherVideo4.png`,
          },
        },
      ],
    },
  ],
}
