import React from 'react'
import Alert from '../Alert'
import { CONTACTAREP_CONTENT } from '../../config/app'

interface Props {
  message?: string
  text?: string
  link?: string
  callback?: () => void
}

const ContentAlert: React.FC<Props> = ({ setShowAlert }) => {
  const contentConfig = CONTACTAREP_CONTENT

  const getButtons = (content: IAlertContent) => {
    const buttons: IAlertContentButton[] = []
    contentConfig.content.buttons.forEach((button) => {
      buttons.push({
        text: button.text,
        link: button.link,
        callback: getLink(button.link),
      })
    })
    return buttons
  }

  const getLink = (url: string) => {
    return () => {
      const win = window.open(url, '_blank')
      win.focus()
    }
  }

  return (
    <Alert
      message={contentConfig.content.message}
      show={true}
      closeText="CLOSE"
      buttons={getButtons(contentConfig)}
      closeCallback={() => {
        setShowAlert(false)
      }}
    />
  )
}

export default ContentAlert
