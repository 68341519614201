import React from 'react'
import { LI1, LI2, Background, Content, ContentIcon } from './styles'

interface Props {
  instance: string
  title: string
  important: boolean
  hasIcon: boolean
  onClick: any
}

/* eslint react-hooks/exhaustive-deps: "off" */
const ListItem: React.FC<Props> = ({ instance, title, important, hasIcon, onClick }) => {
  const Wrapper = hasIcon ? ContentIcon : Content
  const getIcon = (icon: string) => {
    if (!hasIcon) return ''

    const path = `../../assets/svg/${icon}.svg`
    const img = <img src={path} alt={path} />
    return img
  }

  return (
    <>
      {important ? (
        <LI2 onClick={onClick}>
          <Background>
            <Wrapper>
              {getIcon(instance)}
              <p dangerouslySetInnerHTML={{ __html: title }} />
            </Wrapper>
          </Background>
        </LI2>
      ) : (
        <LI1 onClick={onClick}>
          <Background>
            <Wrapper>
              {getIcon(instance)}
              <p dangerouslySetInnerHTML={{ __html: title }} />
            </Wrapper>
          </Background>
        </LI1>
      )}
    </>
  )
}

export default ListItem
