import React, { useContext, useEffect, lazy, Suspense, useState } from 'react'
import styled from 'styled-components'
import * as CONTENT from './config/content/'
import { DesktopNavigation, MobileNavigation } from './components/navigation'
import config from './config/config.json'
import sceneConfig from './config/sceneConfig'
import { UiContext, setShowPopup, setOpen, setAlert, setBoothDefinition } from './reducers/ui'
import { EventContext, setEvent, setFlyThroughActive, setFlyThroughViewed } from './reducers/event'
import arrowCycleRight from './assets/icons/cycle-left-arrow.png'
import arrowCycleLeft from './assets/icons/cycle-right-arrow.png'

import {
  INTRO_CONTENT,
  CONTACTAREP_CONTENT,
  MOBILELANDSCAPEOVERLAY_CONTENT,
  SKIPFLYTHROUGH_CONTENT,
} from './config/app'
import { gaModalViewed } from './utils/gaEvents'
import Alert from './components/Alert'
import BlackFade from './components/BlackFade'
import isMobile from './utils/mobileDetector'
import showWarning from './utils/showWarning'
import ContactRep from './components/ContactRep'
import MobileLandscapeOverlay from './components/Widgets/MobileLandscapeOverlay'
import { hasSeenFlyThrough, setHasSeenFlyThrough } from './utils/localStorage'
import BoothDefinitions from './components/BoothDefinitions'

const ContentWithSidebar = lazy(() => import('./components/Container/ContentWithSidebar'))
const Scene = lazy(() => import('./components/scene'))
const Intro = lazy(() => import('./components/Intro'))

const SkipFlythrough = styled.p<{ open: boolean }>`
  bottom: 5%;
  color: white;
  font-size: 1.25rem;
  left: 50%;
  opacity: ${(props) => (props.open ? '0' : '1')};
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out 0.75s;
  text-shadow: 0 0 4px black;
  z-index: 100;
  :hover {
    color: #f9f9f9;
    cursor: pointer;
    font-size: 1.3rem;
  }
`

// gaInitalise({ gtmId: process.env.GTM_ID || 'GTM-K2HL228' })

const App = () => {
  const MOBILE_ROTATION = {};
  const SCREENS = config.sceneObjects.map(obj => { // eslint-disable-line
    if (obj.circle && obj.mobileRot) {
      MOBILE_ROTATION[obj.circle] = obj.mobileRot
      return obj.circle;
    }
    // return undefined;
  }).filter(el => el != null);

  const [content, setContent] = useState('')
  const [popUpShowing, setPopUpShowing] = useState(false)
  const [cycleHidden, setCycleHidden] = useState(true)
  const { state: uiState, dispatch: uiDispatch } = useContext(UiContext)
  const { state: eventState, dispatch: eventDispatch } = useContext(EventContext)
  const [currentMobileHover, setCurrentMobileHover] = useState(SCREENS[3]);
  const [blackFadeClass, setBlackFadeClass] = useState('hidden')
  const [currentBooth, setCurrentBooth] = useState()
  const [currentLabel, setCurrentLabel] = useState()
  const [hideLeftArrow, setHideLeftArrow] = useState(false)
  const [hideRightArrow, setHideRightArrow] = useState(false)
  
  useEffect(() => {
    if (typeof currentBooth === 'string') {
      setCurrentLabel(currentBooth)
    }
  }, [currentBooth])

  useEffect(() => {
    if (uiState?.currentAction !== '') {
        setPopUpShowing(true)
        setContent(uiState.currentAction)
    uiDispatch(setShowPopup(true))
    }
    if (!popUpShowing) {
      setCurrentMobileHover(SCREENS[3])
    }
  }, [uiState.currentAction, uiDispatch]) // eslint-disable-line

  useEffect(() => {
    if (eventState.eventName === 'END_FLY_THROUGH') {
        onFade()
    }
  }, [eventState]) // eslint-disable-line

  useEffect(() => {
    if (hasSeenFlyThrough()) {
      eventDispatch(setFlyThroughViewed(true))
    }
  }, []) // eslint-disable-line

  const onClose = () => {
    uiDispatch(setShowPopup(false))
    uiDispatch(setBoothDefinition(isMobile ? 'EOS, eosinophils; OCS, oral corticosteroid.' : 'CRS, chronic rhinosinusitis; CRSwNP, chronic rhinosinusitis with nasal polyposis; EOS, eosinophils; OCS, oral corticosteroid.'))
    setPopUpShowing(false)
    eventDispatch(setEvent('MOVE_TO_START'))
    setHideLeftArrow(false)
    setHideRightArrow(false)
  }

  const onAlertClose = () => {
    uiDispatch(setAlert(''))
  }

  const onOpen = () => {
    uiDispatch(setOpen(false))
  }

  const onFade = (dispatchEvents = true) => {
    setBlackFadeClass('visible')
    setTimeout(() => {
		if (dispatchEvents) {
        eventDispatch(setEvent('CANCEL_FLY_THROUGH'))
      }
      eventDispatch(setFlyThroughActive(false))
      eventDispatch(setFlyThroughViewed(true))
      setBlackFadeClass('click-through')
      setHasSeenFlyThrough()
    }, 1200)
  }

  const onNext = () => {
    const currentIndex = SCREENS.indexOf(currentMobileHover);
    if (currentIndex >= 3) {
      setCurrentMobileHover(SCREENS[6])
      setHideRightArrow(true)
    } else {
      setCurrentMobileHover(SCREENS[3])
      setHideLeftArrow(false)
    }
  }
  
  const onPrevious = () => {
    setHideLeftArrow(false)
    const currentIndex = SCREENS.indexOf(currentMobileHover);
    if (currentIndex <= 3) {
      setCurrentMobileHover(SCREENS[0])
      setHideLeftArrow(true)
    } else {
      setCurrentMobileHover(SCREENS[3])
      setHideRightArrow(false)
    }
  }

  return (
    <>
      {isMobile && eventState.flyThroughViewed && !eventState.cameraMoving && <>
        <ArrowContainer style={{ left: '0', display: currentLabel || hideLeftArrow ? 'none' : 'inline' }} onClick={onPrevious}>
          <img width="100%" height="100%" src={arrowCycleRight} alt="arrow-cycle-left" />
        </ArrowContainer>
          <ArrowContainer style={{ right: '0', display: currentLabel || hideRightArrow ? 'none' : 'inline' }} onClick={onNext}>
          <img width="100%" height="100%" src={arrowCycleLeft} alt="arrow-cycle-right" />
        </ArrowContainer>
      </>}

      {isMobile && <MobileLandscapeOverlay content={MOBILELANDSCAPEOVERLAY_CONTENT} />}
      <Suspense fallback={<></>}>
        <Intro isReady={uiState.isReady} content={INTRO_CONTENT} open={uiState.open} setOpen={onOpen} />
      </Suspense>
      {!eventState.flyThroughViewed && (
        <>
          {(blackFadeClass === 'hidden') && (
            <SkipFlythrough onClick={onFade} open={uiState.open}>
              {SKIPFLYTHROUGH_CONTENT.skipMessage}
            </SkipFlythrough>
          )}
          <BlackFade onClick={onFade} className={`${blackFadeClass}`} />
        </>
      )}

      {isMobile ?
        <MobileNavigation setCurrentBooth={setCurrentBooth} currentBooth={currentBooth} setCycleHidden={setCycleHidden} cycleHidden={cycleHidden} popUpShowing={popUpShowing} menuItems={CONTENT.contentNavigation} onClose={onClose} open={false} />
        : !uiState.showPopup &&
          !eventState.cameraMoving &&
          !eventState.flyThroughActive && (
            <DesktopNavigation menuItems={CONTENT.contentNavigation} open={uiState.open} />
          )}
      {uiState.showPopup && content && (
        <>
          <Suspense fallback={<></>}>
            <ContentWithSidebar
              contents={CONTENT[content]}
              onSkip={() => {}}
              onClose={onClose}
              onOpen={(name) => gaModalViewed({ name })}
              onEnded={() => {}}
            />
          </Suspense>
        </>
      )}
      <Suspense fallback={<></>}>
        <Scene
          id="aaaai-scene"
          file="aaaai.gltf"
          path="https://uploads.dupixentvirtualbooth.com/uscongress/scenes/room/"
          config={config}
          sceneConfig={sceneConfig}
          isMobile={isMobile}
          currentMobileHover={currentMobileHover}
          MOBILE_ROTATION={MOBILE_ROTATION}
        />
      </Suspense>
      <Alert
        message="For an optimal experience, please switch your browser to Google Chrome."
        closeText="OK"
        show={showWarning()}
      />
      {uiState.alert && <ContactRep setShowAlert={onAlertClose} content={CONTACTAREP_CONTENT} />}
      <BoothDefinitions />
    </>
  )
}

export default App

const ArrowContainer = styled.span`
    border: none;
    background: none;
    position: absolute;
    top: 45%;
    width: 44px;
    height: 80px;
`