import { videoContentUrl } from '../../api'

export const ASTHMA_VIDEOS = {
  name: 'Asthma Screen',
  title: 'EOSINOPHILIC OR<br />OCS-DEPENDENT ASTHMA',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'MECHANISM OF ACTION (MOA)',
      content: {
        contentTemplate: 'VIDEO',
        content: {
          src: `${videoContentUrl}/asthma-moa-0477.mp4`,
          poster: `${videoContentUrl}/posters/Asthma-MOAVideo.png`,
        },
      },
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'EXPERT DISCUSSIONS',
      subItems: [
        {
          subMenuName: 'DR TAN: ASTHMA MOA',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-tan-asthma-moa.mp4`,
            poster: `${videoContentUrl}/posters/dr-tan-asthma-moa-poster.png`,
          },
        },
        {
          subMenuName: 'DR TAN: OCS-DEPENDENT PATIENT CASE',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-tan-ocs-dependent-patient-case.mp4`,
            poster: `${videoContentUrl}/posters/dr-tan-ocs-dependent-patient-case-poster.png`,
          },
        },
        {
          subMenuName: 'DR TAN: ASTHMA PATIENT DEMOGRAPHICS',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-tan-asthma-patient-demographics.mp4`,
            poster: `${videoContentUrl}/posters/dr-tan-asthma-patient-demographics-poster.png`,
          },
        },
        {
          subMenuName: 'DR SHER: ASTHMA EFFICACY ENDPOINTS',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-asthma-efficacy-endpoints.mp4`,
            poster: `${videoContentUrl}/posters/dr-sher-asthma-efficacy-endpoints-poster.png`,
          },
        },
        {
          subMenuName: 'DR SHER: IMPACT OF DUAL INHIBITION',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-impact-of-dual-inhibition.mp4`,
            poster: `${videoContentUrl}/posters/dr-sher-impact-of-dual-inhibition-poster.png`,
          },
        },
        {
          subMenuName: 'DR SHER: WHAT IS TYPE 2 INFLAMMATION?',
          contentTemplate: 'VIDEO',
          content: {
            src: `${videoContentUrl}/dr-sher-what-is-type-2-inflammation.mp4`,
            poster: `${videoContentUrl}/posters/dr-sher-what-is-type-2-inflammation-poster.png`,
          },
        },
      ],
    },
  ],
}
