import React, { useContext } from 'react'
import {
    BoothDefinitionsContainer
} from "./styles"
import { UiContext } from '../../reducers/ui'

const BoothDefinitions: React.FC<any> = () => {
    const { state: uiState } = useContext(UiContext)

    return (
        <BoothDefinitionsContainer>
            <p>{(uiState.boothDefinition) ? uiState.boothDefinition : ""}</p>
        </BoothDefinitionsContainer>
    )
}

export default BoothDefinitions
