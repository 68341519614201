import React from 'react'
import dupixentLogo from '../../assets/images/dupixent-logo-updated.png'
import menuBurger from '../../assets/images/menu-burger.png'
import menuCross from '../../assets/icons/close-nav-btn.png'
import { MenuHeaderStyled } from './styles'

interface Props {
  toggle: () => void
  menuVisible?: boolean
  showClose: boolean
  popUpShowing: boolean
}

export const MobileHeader: React.FC<Props> = ({ popUpShowing, toggle, menuVisible, showClose }) => {
  return (
    <MenuHeaderStyled>
      <>
        <img src={dupixentLogo} alt="dupixentLogo" width="163" />
        {!menuVisible && (
          <div onClick={toggle}>
            <img src={menuBurger} style={{ cursor: 'pointer', float: 'right', display: showClose || popUpShowing ? 'none' : 'block'}} alt="" width="50%" height="50%" />
            <img src={menuCross} style={{ cursor: 'pointer', float: 'right', display: showClose || popUpShowing ? 'block' : 'none'}} alt="" width="50%" height="50%" />
          </div>
        )}
      </>
    </MenuHeaderStyled>
  )
}
