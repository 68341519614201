import { IGaContentProps, IGaContentDownloadEvent, gaLogEvent } from '../'

export const gaContentDownload = ({ name, type }: IGaContentProps) => {
  const event: IGaContentDownloadEvent = {
    event: 'contentDownload',
    DLV_content_download_type: type,
    DLV_content_download_name: name,
  }
  gaLogEvent(event)
}
