import { videoContentUrl } from '../../api'

export const DOSING_DOWNLOADS = {
  name: 'Dosing Totum',
  title: '<i>DUPIXENT MyWay</i>&nbsp;&reg;',
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: '<p><i>DUPIXENT MyWay</i>&nbsp;&reg;&nbsp; ENROLLMENT</p>',
      content: {
        contentTemplate: 'VIDEO',
        content: {
          src: `${videoContentUrl}/dupixient-myway-enrollment.mp4`,
          poster: `${videoContentUrl}/posters/Dosing-MyWayEnrollmentVideo.png`,
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: '<p><i>DUPIXENT MyWay</i>&nbsp;&reg;&nbsp;DELIVERY</p>',
      content: {
        contentTemplate: 'VIDEO',
        content: {
          src: `${videoContentUrl}/dupixient-myway-delivery.mp4`,
          poster: `${videoContentUrl}/posters/Dosing-MyWayDeliveryVideo.png`,
        },
      },
    },
  ],
}
