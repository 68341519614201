export * from './asthma'
export * from './atopic-dermatitis'
export * from './CRSwNP'
export * from './dosing'

export enum ContentTypes {
  ASTHMA_DOWNLOADS = 'ASTHMA_DOWNLOADS',
  ASTHMA_VIDEOS = 'ASTHMA_VIDEOS',
  ATOPIC_DOWNLOADS = 'DERMATITIS_DOWNLOADS',
  ATOPIC_VIDEOS = 'DERMATITIS_VIDEOS',
  CRSwNP_DOWNLOADS = 'CRSwNP_DOWNLOADS',
  CRSwNP_VIDEOS = 'CRSwNP_VIDEOS',
  DOSING_DOWNLOADS = 'DOSING_DOWNLOADS',
}

export const contentNavigation = [
  { title: 'ATOPIC DERMATITIS VIDEOS & DATA', instance: ContentTypes.ATOPIC_VIDEOS },
  { title: 'ATOPIC DERMATITIS DOWNLOADS', instance: ContentTypes.ATOPIC_DOWNLOADS },
  { title: 'ASTHMA DOWNLOADS', instance: ContentTypes.ASTHMA_DOWNLOADS },
  { title: 'ASTHMA VIDEOS', instance: ContentTypes.ASTHMA_VIDEOS },
  { title: '<i>DUPIXENT MyWay</i>&nbsp;&reg;', instance: ContentTypes.DOSING_DOWNLOADS },
  { title: 'CRSwNP DOWNLOADS', instance: ContentTypes.CRSwNP_DOWNLOADS },
  { title: 'CRSwNP VIDEOS', instance: ContentTypes.CRSwNP_VIDEOS },
  { title: 'CONTACT A REP', desktopOnly: true, alert: 'ContactRep' },
  { title: 'PATIENT SUPPORT PROGRAM', mobileOnly: true, dropdown: [
    { subTitle: '<b><i>DUPIXENT MyWay</i></b>® Program Overview', link: 'https://www.dupixenthcp.com/patient-support/dupixent-myway' }, 
    { subTitle: 'Patient Enrollment', link: 'https://www.dupixenthcp.com/patient-support/patient-enrollment' },
    { subTitle: 'Insurance Coverage Support', link: 'https://www.dupixenthcp.com/patient-support/coverage-support' },
    { subTitle: 'Copay and Patient Access Support', link: 'https://www.dupixenthcp.com/patient-support/copay-card' },
    { subTitle: 'Nursing Support', link: 'https://www.dupixenthcp.com/patient-support/nursing-support' }
  ]},
  { title: 'VISIT PATIENT SITE', mobileOnly: true, link: 'https://www.dupixent.com/' },
]
