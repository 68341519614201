import React, { createContext, useReducer } from 'react'
import GuiLabel from '../components/scene-render/utils/GuiLabel'

const initialState = {
  eventName: '',
  eventAction: '',
  cameraMoving: false,
  flyThroughActive: false,
  flyThroughViewed: false,
  hoverMeshName: '',
  label: null
}

//types
const SET_EVENT = 'SET_EVENT'
const SET_CAMERA_MOVING = 'SET_CAMERA_MOVING'
const SET_FLY_THROUGH_ACTIVE = 'SET_FLY_THROUGH_ACTIVE'
const SET_HOVER_MESH_NAME = 'SET_HOVER_MESH_NAME'
const SET_FLY_THROUGH_VIEWED = 'SET_FLY_THROUGH_VIEWED'
const SET_LABEL =  'SET_LABEL';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_EVENT:
      return {
        ...state,
        eventName: action.eventName,
        eventAction: action.eventAction,
      }
    case SET_CAMERA_MOVING:
      return {
        ...state,
        cameraMoving: action.cameraMoving,
      }
    case SET_FLY_THROUGH_ACTIVE:
      return {
        ...state,
        flyThroughActive: action.flyThroughActive,
      }
    case SET_FLY_THROUGH_VIEWED:
      return {
        ...state,
        flyThroughViewed: action.flyThroughViewed,
      }
    case SET_HOVER_MESH_NAME:
      return {
        ...state,
        hoverMeshName: action.hoverMeshName,
      }
    case SET_LABEL:
      return {
          ...state,
          label: action.label,
      }
    default:
      return state
  }
}

// action creators
// add action type
export const setEvent = (eventName: string, eventAction: string = '') => {
  return {
    type: SET_EVENT,
    eventName: eventName,
    eventAction: eventAction,
  }
}

export const setCameraMoving = (moving: boolean) => {
  return {
    type: SET_CAMERA_MOVING,
    cameraMoving: moving,
  }
}

export const setFlyThroughActive = (active: boolean) => {
  return {
    type: SET_FLY_THROUGH_ACTIVE,
    flyThroughActive: active,
  }
}

export const setFlyThroughViewed = (viewed: boolean) => {
  return {
    type: SET_FLY_THROUGH_VIEWED,
    flyThroughViewed: viewed,
  }
}

export const setHoverMeshName = (meshName: string) => {
  return {
    type: SET_HOVER_MESH_NAME,
    hoverMeshName: meshName,
  }
}

export const setLabel = (label?: GuiLabel) => {
    if (label) {
        return {
            type: SET_LABEL,
            label: label,
          }
    } else {
        return {
            type: SET_LABEL,
            label: null
        }
    }

  }

export const EventContext = createContext({
  state: initialState,
  dispatch: null,
})

export const EventState = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <EventContext.Provider value={{ state, dispatch }}>{children}</EventContext.Provider>
}
