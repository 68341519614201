import styled from "styled-components";

export const BoothDefinitionsContainer = styled.div`
    color: #796E65;
    position: absolute;
    left: 10px;
    bottom: 0;
    font-size: 13px;
    max-width: 400px;
    font-weight: bold;
`;
